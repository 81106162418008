import React from 'react';
import './mob_homeScreen.scss';
import See from "../../../props/icons/greater.svg"
//import RSlider from '../../slider/RSlider';
import Product from '../../product/Product';
import Header from '../../header/Header';
import Search from '../../search/Search';
import Banner from '../../banner/Banner';
import offer from "../../../props/icons/Iconss.svg";
import WebFooter from '../../webFooter/WebFooter';
function HomeScreen(){
  return (
    <div className="HomeScreen">
      <div className="HomeScreen__desktopHeader">
        <Header/>
      </div>
      <div className="HomeScreen__banner__sticky">
        <Header Main />
        <Search />
      </div>
      <div className='HomeScreen__container'>
      <div className='HomeScreen__top'>
        <div className='HomeScreen__top__categories'></div>
      <Banner />
      <div className='HomeScreen__top__ads'>
        <div></div>
        <div></div>
      </div>
      </div>
      {/* <RSlider/> */}
      <div className="HomeScreen__giveaway">
        <div className="banner">
          <p className="heading">Recommended</p>
          <span>See All</span>
          <span>
            <img src={See} alt="see" className='see'/>
          </span>
        </div>

        <div className="products">
          <Product OTF />
          <Product />
          <Product />
          <Product />
          
        </div>
      </div>

      <div className="HomeScreen__day">
        <div className="banner">
          <p className="heading">Discover Swaps</p>
          <span>See All</span>
          <span>
            <img src={See} alt="see" className='see'/>
          </span>
        </div>
        <div className="products">
          <Product OTF/>
          <Product />
          <Product />
          <Product OTF/>
          
        </div>
      </div>
      <div className="HomeScreen__year">
        <div className="banner">
          <p className="heading">
            Open Offers
            <span>
              <img src={offer} alt="offers" className='offer'/>
            </span>
          </p>
          <span>See All</span>
          <span>
            <img src={See} alt="see" className='see'/>
          </span>
        </div>
        <div className="products">
          <Product OTF />
          <Product OTF />
          <Product OTF />
          <Product OTF />
         
        </div>
      </div>
      </div>

      <WebFooter/>
    </div>
  );
}
export default HomeScreen;