import React from 'react';
import Tabx from '../../components/tab/Tab';

function Swap(){
  return(
    <div className="Swap">
        <Tabx />
    </div>
  )
}
export default Swap;