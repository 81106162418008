import React from "react";
import "./mob_productpage.scss";
import logo from "../../props/icons/kap-logo.svg";
import phone from "../../props/images/iphone.png";
import RatedStar from "../../props/icons/rated-star.svg";
import unratedStar from "../../props/icons/unrated-star.svg";
import chatUp from "../../props/icons/Chat_alt_2_fill.svg";
import map_pin from "../../props/icons/Map_pin.svg";
import right from "../../props/icons/Expand_right.svg";
import Review from "../../components/review/Review";
import { useNavigate } from "react-router";
import Footer from "../../components/footer/Footer";
import Product from "../../components/product/Product";
import { useDispatch } from "react-redux";
import Header from "../../components/header/Header";

function ProductPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChatUp = () => {
    navigate("/chat");
  };
  const checkProfile = () => {
    navigate("/swapper/@gbiaye");
  };
  const handleSwap = () => {
    dispatch({
      type: "preamble",
    });
    navigate("/chat");
  };
  return (
    <main className="ProductPage">
      {/* <Header/> */}
      <header>
        <div className="mobile__header">
          <img src={logo} alt="kap-logo" />
        </div>

        <div className="header">
          <img src={logo} alt="kap-logo" />
          <nav>
            <ul>
              <li>
                <svg
                  width="28"
                  height="28"
                  viewBox="0 0 28 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <ellipse
                    cx="14"
                    cy="11.6667"
                    rx="3.5"
                    ry="3.5"
                    stroke="#4F4F4F"
                    stroke-width="2.43478"
                    stroke-linecap="round"
                  />
                  <circle
                    cx="14"
                    cy="14"
                    r="10.5"
                    stroke="#4F4F4F"
                    stroke-width="2.43478"
                  />
                  <path
                    d="M20.7439 21.9641C20.8923 21.8827 20.9624 21.7064 20.8997 21.5493C20.4498 20.4219 19.5832 19.429 18.4117 18.7054C17.146 17.9237 15.5953 17.5 14 17.5C12.4047 17.5 10.854 17.9237 9.58835 18.7054C8.41684 19.429 7.5502 20.4219 7.10031 21.5493C7.03761 21.7064 7.10775 21.8827 7.25606 21.9641C11.4563 24.2701 16.5437 24.2702 20.7439 21.9641Z"
                    fill="#4F4F4F"
                  />
                </svg>
                <p>Accounts</p>
              </li>
              <li>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20H15C16.5531 20 17.3297 20 17.9423 19.7463C18.759 19.408 19.408 18.759 19.7463 17.9423C20 17.3297 20 16.5531 20 15V10C20 4.47715 15.5228 0 10 0ZM5.55556 8.88889C5.55556 8.27524 6.05302 7.77778 6.66667 7.77778H13.3333C13.947 7.77778 14.4444 8.27524 14.4444 8.88889C14.4444 9.50254 13.947 10 13.3333 10H6.66667C6.05302 10 5.55556 9.50254 5.55556 8.88889ZM8.88889 13.3333C8.88889 12.7197 9.38635 12.2222 10 12.2222H13.3333C13.947 12.2222 14.4444 12.7197 14.4444 13.3333C14.4444 13.947 13.947 14.4444 13.3333 14.4444H10C9.38635 14.4444 8.88889 13.947 8.88889 13.3333Z"
                    fill="#696969"
                  />
                </svg>
                <p>Chat</p>
              </li>
              <li>
                <svg
                  width="20"
                  height="19"
                  viewBox="0 0 20 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.0225227 4.89884C-0.0381844 0.82869 5.15227 -1.56923 8.70364 1.17576C9.49283 1.77524 9.97849 1.77524 10.8587 1.17576C14.3494 -1.31681 18.9328 0.386969 19.6613 4.86729C19.9041 6.38176 19.4184 7.73847 18.7507 9.03209C17.324 11.8086 15.1082 13.9226 12.8924 16.005C12.1639 16.6991 11.3747 17.267 10.6159 17.9296C10.1303 18.3398 9.6446 18.4344 9.09823 17.9927C6.15394 15.5948 3.30071 13.1022 1.26702 9.75777C0.508179 8.46416 -0.129245 7.10744 0.0225227 4.89884Z"
                    fill="#696969"
                  />
                </svg>
                <p>My Interest</p>
              </li>
            </ul>
          </nav>
        </div>
      </header>
      <section className="ProductPage__product">
        <section className="ProductPage__product__info">
          <article className="ProductPage__product__info__content">
            <img src={phone} alt="iphone-xr" />
            <div className="ProductPage__product__info__content__hero">
              <div className="ProductPage__product__info__content__hero__wrap">
                <div className="ProductPage__product__info__content__hero__wrap__mobile__review">
                  <svg
                    width="99"
                    height="16"
                    viewBox="10 0 96 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.59035 2.01428C9.46437 1.76231 9.20683 1.60315 8.92512 1.60315C8.64341 1.60315 8.38588 1.76231 8.25989 2.01428L6.20064 6.13278L1.74641 6.81805C1.46242 6.86174 1.22901 7.06509 1.14682 7.34041C1.06464 7.61573 1.14839 7.91377 1.36196 8.10597L4.75502 11.1597L3.73792 15.5672C3.67121 15.8563 3.78269 16.1573 4.02159 16.3333C4.26049 16.5092 4.5811 16.5263 4.83738 16.3768L8.92512 13.9923L13.0129 16.3768C13.2691 16.5263 13.5898 16.5092 13.8287 16.3333C14.0676 16.1573 14.179 15.8563 14.1123 15.5672L13.0952 11.1597L16.4883 8.10597C16.7019 7.91377 16.7856 7.61573 16.7034 7.34041C16.6212 7.06509 16.3878 6.86174 16.1038 6.81805L11.6496 6.13278L9.59035 2.01428Z"
                      fill="#FEE440"
                    />
                    <path
                      d="M31.9031 2.01428C31.7771 1.76231 31.5196 1.60315 31.2379 1.60315C30.9562 1.60315 30.6986 1.76231 30.5726 2.01428L28.5134 6.13278L24.0592 6.81805C23.7752 6.86174 23.5418 7.06509 23.4596 7.34041C23.3774 7.61573 23.4611 7.91377 23.6747 8.10597L27.0678 11.1597L26.0507 15.5672C25.984 15.8563 26.0954 16.1573 26.3343 16.3333C26.5732 16.5092 26.8938 16.5263 27.1501 16.3768L31.2379 13.9923L35.3256 16.3768C35.5819 16.5263 35.9025 16.5092 36.1414 16.3333C36.3803 16.1573 36.4918 15.8563 36.4251 15.5672L35.408 11.1597L38.801 8.10597C39.0146 7.91377 39.0984 7.61573 39.0162 7.34041C38.934 7.06509 38.7006 6.86174 38.4166 6.81805L33.9623 6.13278L31.9031 2.01428Z"
                      fill="#FEE440"
                    />
                    <path
                      d="M76.5281 2.01428C76.4021 1.76231 76.1446 1.60315 75.8629 1.60315C75.5812 1.60315 75.3236 1.76231 75.1976 2.01428L73.1384 6.13278L68.6842 6.81805C68.4002 6.86174 68.1668 7.06509 68.0846 7.34041C68.0024 7.61573 68.0861 7.91377 68.2997 8.10597L71.6928 11.1597L70.6757 15.5672C70.609 15.8563 70.7204 16.1573 70.9593 16.3333C71.1982 16.5092 71.5188 16.5263 71.7751 16.3768L75.8629 13.9923L79.9506 16.3768C80.2069 16.5263 80.5275 16.5092 80.7664 16.3333C81.0053 16.1573 81.1168 15.8563 81.0501 15.5672L80.033 11.1597L83.426 8.10597C83.6396 7.91377 83.7234 7.61573 83.6412 7.34041C83.559 7.06509 83.3256 6.86174 83.0416 6.81805L78.5873 6.13278L76.5281 2.01428Z"
                      fill="#FEE440"
                    />
                    <path
                      d="M54.2156 2.01428C54.0896 1.76231 53.8321 1.60315 53.5504 1.60315C53.2687 1.60315 53.0111 1.76231 52.8851 2.01428L50.8259 6.13278L46.3717 6.81805C46.0877 6.86174 45.8543 7.06509 45.7721 7.34041C45.6899 7.61573 45.7736 7.91377 45.9872 8.10597L49.3803 11.1597L48.3632 15.5672C48.2965 15.8563 48.4079 16.1573 48.6468 16.3333C48.8857 16.5092 49.2063 16.5263 49.4626 16.3768L53.5504 13.9923L57.6381 16.3768C57.8944 16.5263 58.215 16.5092 58.4539 16.3333C58.6928 16.1573 58.8043 15.8563 58.7376 15.5672L57.7205 11.1597L61.1135 8.10597C61.3271 7.91377 61.4109 7.61573 61.3287 7.34041C61.2465 7.06509 61.0131 6.86174 60.7291 6.81805L56.2748 6.13278L54.2156 2.01428Z"
                      fill="#FEE440"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M98.1754 1.60315C98.4571 1.60315 98.7146 1.76231 98.8406 2.01428L100.9 6.13278L105.354 6.81805C105.638 6.86174 105.871 7.06509 105.954 7.34041C106.036 7.61573 105.952 7.91376 105.739 8.10597L102.345 11.1597L103.363 15.5672C103.429 15.8563 103.318 16.1573 103.079 16.3333C102.84 16.5092 102.519 16.5263 102.263 16.3768L98.1754 13.9923L94.0876 16.3768C93.8313 16.5263 93.5107 16.5092 93.2718 16.3333C93.0329 16.1573 92.9215 15.8563 92.9882 15.5672L94.0053 11.1597L90.6122 8.10597C90.3986 7.91376 90.3149 7.61573 90.3971 7.34041C90.4793 7.06509 90.7127 6.86174 90.9967 6.81805L95.4509 6.13278L97.5101 2.01428C97.6361 1.76231 97.8937 1.60315 98.1754 1.60315ZM98.1754 4.00998L96.6093 7.14201C96.5015 7.35778 96.2956 7.50782 96.0572 7.5445L92.7733 8.04972L95.326 10.3472C95.527 10.5281 95.614 10.8038 95.5532 11.0673L94.8245 14.2249L97.8006 12.4888C98.0322 12.3538 98.3185 12.3538 98.5501 12.4888L101.526 14.2249L100.798 11.0673C100.737 10.8038 100.824 10.5281 101.025 10.3472L103.577 8.04972L100.294 7.5445C100.055 7.50782 99.8493 7.35778 99.7414 7.14201L98.1754 4.00998Z"
                      fill="#E0E0E0"
                    />
                  </svg>
                  <p>(3)</p>
                </div>
                <h1>Iphone XR - Swap for 4GB RAM/ 1TB HDD Laptop</h1>
                <div className="ProductPage__product__info__content__hero__wrap__location">
                  <svg
                    width="17"
                    height="20"
                    viewBox="0 0 17 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.4081 6.90077C15.4081 11.5959 10.7256 14.4636 9.18729 15.279C8.88197 15.4409 8.52614 15.4409 8.22082 15.279C6.68252 14.4636 2 11.5959 2 6.90077C2 3.19822 5.00151 0.196716 8.70406 0.196716C12.4066 0.196716 15.4081 3.19822 15.4081 6.90077Z"
                      fill="#57CC99"
                    />
                    <circle
                      cx="8.70398"
                      cy="6.90078"
                      r="2.87317"
                      fill="white"
                    />
                    <path
                      d="M14.5099 14.0837C15.0983 14.5205 15.4081 15.0159 15.4081 15.5203C15.4081 16.0246 15.0983 16.5201 14.5099 16.9569C13.9215 17.3937 13.0752 17.7564 12.0561 18.0085C11.0369 18.2607 9.88086 18.3935 8.70406 18.3935C7.52725 18.3935 6.37117 18.2607 5.35203 18.0085C4.33288 17.7564 3.48658 17.3937 2.89817 16.9569C2.30977 16.5201 2 16.0246 2 15.5203C2 15.0159 2.30977 14.5205 2.89817 14.0837"
                      stroke="#57CC99"
                      stroke-width="2.42623"
                      stroke-linecap="round"
                    />
                  </svg>

                  <p>Benin City, Edo State</p>
                </div>
              </div>
              <div className="ProductPage__product__info__content__hero__actions">
                <button
                  className="ProductPage__product__info__content__hero__actions__swap"
                  onClick={handleSwap}
                >
                  Swap
                </button>
                <button className="ProductPage__product__info__content__hero__actions__save">
                  <svg
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.97812 14.1946L10.8315 21.1925C11.1232 21.4903 11.2691 21.6393 11.4487 21.6578C11.4828 21.6613 11.5171 21.6613 11.5511 21.6578C11.7308 21.6393 11.8766 21.4903 12.1683 21.1925L19.0217 14.1946C20.7688 12.4107 20.9848 9.63055 19.5341 7.59821L19.026 6.8864C17.1864 4.30915 13.2401 4.71741 11.967 7.61669C11.7884 8.02345 11.2114 8.02345 11.0328 7.61669C9.75974 4.71741 5.81344 4.30915 3.97381 6.8864L3.46573 7.59821C2.01506 9.63056 2.23101 12.4107 3.97812 14.1946Z"
                      fill="#333333"
                      stroke="#333333"
                      strokeWidth="2"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </article>
          <article className="ProductPage__product__info__description">
            <h2>Description</h2>
            <p>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Neque
              eum, consequatur ut iusto itaque sed distinctio recusandae laborum
              possimus quisquam a ipsa hic provident reiciendis veritatis
              facilis debitis ratione est? Lorem ipsum dolor sit amet
              consectetur adipisicing elit. Recusandae vero eveniet odit?
              Consectetur ipsam ullam maiores deserunt perferendis. Doloribus,
              tempora consequatur? Ducimus sunt fuga ut odit doloribus rerum
              eaque tenetur?
            </p>
          </article>
        </section>
        <section className="ProductPage__product__items">
          <h2>Gbiaye's Items</h2>
          <div className="ProductPage__product__items__list">
            <ul>
              <div>
                <Product />
              </div>
              <div>
                <Product />
              </div>
              <div>
                <Product />
              </div>
              <div>
                <Product />
              </div>
              <div>
                <Product />
              </div>
              <div>
                <Product />
              </div>
            </ul>
          </div>
        </section>
      </section>
      <aside className="ProductPage__side">
        <section className="ProductPage__side__swapper">
          <p className="ProductPage__side__swapper__heading">About Swapper</p>
          <div className="ProductPage__side__swapper__profile">
            <div className="ProductPage__side__swapper__profile__group">
              <img src={phone} alt="gbiaye-barnabas" />
              <div>
                <p className="heading">Barnabas Gbiaye</p>
                <p>Swapper</p>
              </div>
            </div>
            <svg
              width="8"
              height="16"
              viewBox="0 0 12 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.43481 9.68965L10.435 8.68942L11.4353 9.68965L10.435 10.6899L9.43481 9.68965ZM2.4193 0.673679L10.435 8.68942L8.43458 10.6899L0.418839 2.67414L2.4193 0.673679ZM10.435 10.6899L2.4193 18.7056L0.418839 16.7052L8.43458 8.68942L10.435 10.6899Z"
                fill="#1E1E1E"
              />
            </svg>
          </div>
          <div className="ProductPage__side__swapper__score">
            <p>
              <span>60% </span>Swap Score
            </p>
            {/* this will represent the user rating system for this swapper */}
            <svg
              width="89"
              height="16"
              viewBox="0 0 89 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M42.3258 3.41893C42.8882 1.64939 43.1693 0.764618 43.6529 0.764618C44.1364 0.764618 44.4175 1.64939 44.9799 3.41893L45.0061 3.50133C45.3238 4.50103 45.4826 5.00089 45.8064 5.3047C46.1301 5.60852 46.5551 5.65656 47.4051 5.75264L47.5587 5.77001C48.9498 5.92726 49.6454 6.00589 49.7942 6.56444C49.943 7.12299 49.4265 7.71615 48.3934 8.90248L48.0486 9.29842C47.5257 9.89896 47.2642 10.1992 47.1423 10.5928C47.1196 10.6662 47.1007 10.7414 47.0857 10.8179C47.0056 11.2282 47.0822 11.6638 47.2353 12.535L47.283 12.8062C47.5644 14.4073 47.7052 15.2079 47.4595 15.5532C47.3676 15.6822 47.2483 15.7751 47.1158 15.8207C46.761 15.9429 46.2574 15.4249 45.2501 14.3889C44.5887 13.7086 44.258 13.3685 43.8783 13.292C43.7289 13.2619 43.5768 13.2619 43.4274 13.292C43.0477 13.3685 42.717 13.7086 42.0556 14.3889C41.0483 15.4249 40.5447 15.9429 40.1899 15.8207C40.0574 15.7751 39.9381 15.6822 39.8462 15.5532C39.6005 15.2079 39.7413 14.4073 40.0227 12.8062L40.0704 12.535C40.2235 11.6638 40.3001 11.2282 40.22 10.8179C40.205 10.7414 40.1861 10.6662 40.1634 10.5928C40.0415 10.1992 39.78 9.89896 39.2571 9.29842L38.9123 8.90248C37.8792 7.71615 37.3627 7.12299 37.5115 6.56444C37.6603 6.00589 38.3559 5.92726 39.747 5.77001L39.9006 5.75264C40.7506 5.65656 41.1756 5.60852 41.4993 5.3047C41.8231 5.00089 41.9819 4.50103 42.2996 3.50133L42.3258 3.41893Z"
                fill="#FBBC05"
              />
              <path
                d="M61.2157 3.41893C61.903 1.64939 62.2467 0.764618 62.8376 0.764618C63.4286 0.764618 63.7723 1.64939 64.4596 3.41893L64.4916 3.50133C64.8799 4.50103 65.074 5.00089 65.4697 5.3047C65.8654 5.60852 66.3848 5.65656 67.4237 5.75264L67.6115 5.77001C69.3117 5.92726 70.1618 6.00589 70.3437 6.56444C70.5256 7.12299 69.8943 7.71615 68.6317 8.90248L68.2102 9.29842C67.5711 9.89896 67.2515 10.1992 67.1025 10.5928C67.0747 10.6662 67.0516 10.7414 67.0334 10.8179C66.9355 11.2282 67.029 11.6638 67.2162 12.535L67.2745 12.8062C67.6185 14.4073 67.7905 15.2079 67.4902 15.5532C67.3779 15.6822 67.2321 15.7751 67.0701 15.8207C66.6365 15.9429 66.021 15.4249 64.7898 14.3889C63.9814 13.7086 63.5772 13.3685 63.1132 13.292C62.9306 13.2619 62.7446 13.2619 62.5621 13.292C62.098 13.3685 61.6938 13.7086 60.8854 14.3889C59.6543 15.4249 59.0388 15.9429 58.6052 15.8207C58.4432 15.7751 58.2973 15.6822 58.1851 15.5532C57.8848 15.2079 58.0568 14.4073 58.4008 12.8062L58.4591 12.535C58.6462 11.6638 58.7398 11.2282 58.6419 10.8179C58.6236 10.7414 58.6005 10.6662 58.5728 10.5928C58.4238 10.1992 58.1042 9.89896 57.465 9.29842L57.0436 8.90248C55.781 7.71615 55.1497 7.12299 55.3316 6.56444C55.5135 6.00589 56.3636 5.92726 58.0638 5.77001L58.2516 5.75264C59.2904 5.65656 59.8099 5.60852 60.2056 5.3047C60.6012 5.00089 60.7954 4.50103 61.1837 3.50133L61.2157 3.41893Z"
                fill="#FBBC05"
                fill-opacity="0.5"
              />
              <path
                d="M79.0302 3.41893C79.7175 1.64939 80.0611 0.764618 80.6521 0.764618C81.2431 0.764618 81.5867 1.64939 82.274 3.41893L82.306 3.50133C82.6943 4.50103 82.8885 5.00089 83.2842 5.3047C83.6799 5.60852 84.1993 5.65656 85.2381 5.75264L85.426 5.77001C87.1262 5.92726 87.9763 6.00589 88.1582 6.56444C88.3401 7.12299 87.7088 7.71615 86.4461 8.90248L86.0247 9.29842C85.3855 9.89896 85.0659 10.1992 84.917 10.5928C84.8892 10.6662 84.8661 10.7414 84.8478 10.8179C84.7499 11.2282 84.8435 11.6638 85.0307 12.535L85.0889 12.8062C85.4329 14.4073 85.6049 15.2079 85.3046 15.5532C85.1924 15.6822 85.0466 15.7751 84.8845 15.8207C84.451 15.9429 83.8354 15.4249 82.6043 14.3889C81.7959 13.7086 81.3917 13.3685 80.9276 13.292C80.7451 13.2619 80.5591 13.2619 80.3766 13.292C79.9125 13.3685 79.5083 13.7086 78.6999 14.3889C77.4688 15.4249 76.8532 15.9429 76.4196 15.8207C76.2576 15.7751 76.1118 15.6822 75.9996 15.5532C75.6993 15.2079 75.8713 14.4073 76.2153 12.8062L76.2735 12.535C76.4607 11.6638 76.5543 11.2282 76.4564 10.8179C76.4381 10.7414 76.415 10.6662 76.3872 10.5928C76.2383 10.1992 75.9187 9.89896 75.2795 9.29842L74.8581 8.90248C73.5954 7.71615 72.9641 7.12299 73.146 6.56444C73.3279 6.00589 74.178 5.92726 75.8782 5.77001L76.066 5.75264C77.1049 5.65656 77.6243 5.60852 78.02 5.3047C78.4157 5.00089 78.6098 4.50103 78.9982 3.50133L79.0302 3.41893Z"
                fill="#FBBC05"
                fill-opacity="0.5"
              />
              <path
                d="M23.141 3.41893C23.7033 1.64939 23.9845 0.764618 24.468 0.764618C24.9515 0.764618 25.2327 1.64939 25.7951 3.41893L25.8213 3.50133C26.139 4.50103 26.2978 5.00089 26.6216 5.3047C26.9453 5.60852 27.3703 5.65656 28.2203 5.75264L28.3739 5.77001C29.765 5.92726 30.4605 6.00589 30.6094 6.56444C30.7582 7.12299 30.2417 7.71615 29.2086 8.90248L28.8638 9.29842C28.3408 9.89896 28.0794 10.1992 27.9575 10.5928C27.9348 10.6662 27.9159 10.7414 27.9009 10.8179C27.8208 11.2282 27.8974 11.6638 28.0505 12.535L28.0982 12.8062C28.3796 14.4073 28.5203 15.2079 28.2746 15.5532C28.1828 15.6822 28.0635 15.7751 27.9309 15.8207C27.5762 15.9429 27.0726 15.4249 26.0653 14.3889C25.4039 13.7086 25.0732 13.3685 24.6935 13.292C24.5441 13.2619 24.3919 13.2619 24.2426 13.292C23.8629 13.3685 23.5322 13.7086 22.8708 14.3889C21.8635 15.4249 21.3599 15.9429 21.0051 15.8207C20.8726 15.7751 20.7532 15.6822 20.6614 15.5532C20.4157 15.2079 20.5565 14.4073 20.8379 12.8062L20.8856 12.535C21.0387 11.6638 21.1153 11.2282 21.0352 10.8179C21.0202 10.7414 21.0013 10.6662 20.9786 10.5928C20.8567 10.1992 20.5952 9.89896 20.0723 9.29842L19.7275 8.90248C18.6944 7.71615 18.1779 7.12299 18.3267 6.56444C18.4755 6.00589 19.1711 5.92726 20.5621 5.77001L20.7158 5.75264C21.5658 5.65656 21.9908 5.60852 22.3145 5.3047C22.6383 5.00089 22.7971 4.50103 23.1148 3.50133L23.141 3.41893Z"
                fill="#FBBC05"
              />
              <path
                d="M5.32679 3.41893C5.88914 1.64939 6.17031 0.764618 6.65383 0.764618C7.13734 0.764618 7.41852 1.64939 7.98087 3.41893L8.00705 3.50133C8.32475 4.50103 8.4836 5.00089 8.80735 5.3047C9.1311 5.60852 9.55608 5.65656 10.4061 5.75264L10.5597 5.77001C11.9508 5.92726 12.6463 6.00589 12.7952 6.56444C12.944 7.12299 12.4275 7.71615 11.3944 8.90248L11.0496 9.29842C10.5266 9.89896 10.2652 10.1992 10.1433 10.5928C10.1205 10.6662 10.1016 10.7414 10.0867 10.8179C10.0066 11.2282 10.0832 11.6638 10.2363 12.535L10.284 12.8062C10.5654 14.4073 10.7061 15.2079 10.4604 15.5532C10.3686 15.6822 10.2493 15.7751 10.1167 15.8207C9.762 15.9429 9.25836 15.4249 8.25108 14.3889C7.58967 13.7086 7.25896 13.3685 6.87927 13.292C6.72992 13.2619 6.57773 13.2619 6.42838 13.292C6.04869 13.3685 5.71798 13.7086 5.05657 14.3889C4.04929 15.4249 3.54566 15.9429 3.19092 15.8207C3.05836 15.7751 2.93903 15.6822 2.84722 15.5532C2.60152 15.2079 2.74224 14.4073 3.02368 12.8062L3.07135 12.535C3.22449 11.6638 3.30106 11.2282 3.22095 10.8179C3.20601 10.7414 3.18711 10.6662 3.16438 10.5928C3.0425 10.1992 2.78102 9.89896 2.25806 9.29842L1.91327 8.90248C0.880194 7.71615 0.363655 7.12299 0.512484 6.56444C0.661314 6.00589 1.35686 5.92726 2.74794 5.77001L2.9016 5.75264C3.75157 5.65656 4.17655 5.60852 4.5003 5.3047C4.82405 5.00089 4.9829 4.50103 5.3006 3.50133L5.32679 3.41893Z"
                fill="#FBBC05"
              />
            </svg>
          </div>
          <div className="ProductPage__side__swapper__message">
            <svg
              width="46"
              height="46"
              viewBox="0 0 46 46"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M38.2211 20.7466C38.2211 15.4488 38.2211 12.7999 36.9497 10.8971C36.3993 10.0733 35.692 9.36607 34.8682 8.81565C32.9654 7.54422 30.3165 7.54422 25.0187 7.54422H21.2466C15.9488 7.54422 13.2999 7.54422 11.3971 8.81565C10.5733 9.36607 9.86604 10.0733 9.31562 10.8971C8.04419 12.7999 8.04419 15.4488 8.04419 20.7466C8.04419 26.0444 8.04419 28.6933 9.31562 30.5961C9.86604 31.4199 10.5733 32.1272 11.3971 32.6776C13.0593 33.7883 15.291 33.9287 19.3605 33.9464V33.949L21.4457 38.1194C22.1408 39.5095 24.1245 39.5095 24.8196 38.1194L26.9048 33.949V33.9464C30.9743 33.9287 33.2059 33.7883 34.8682 32.6776C35.692 32.1272 36.3993 31.4199 36.9497 30.5961C38.2211 28.6933 38.2211 26.0444 38.2211 20.7466ZM17.4745 16.0315C16.9537 16.0315 16.5314 16.4537 16.5314 16.9745C16.5314 17.4953 16.9537 17.9175 17.4745 17.9175H28.7908C29.3116 17.9175 29.7338 17.4953 29.7338 16.9745C29.7338 16.4537 29.3116 16.0315 28.7908 16.0315H17.4745ZM17.4745 23.5757C16.9537 23.5757 16.5314 23.9979 16.5314 24.5187C16.5314 25.0396 16.9537 25.4618 17.4745 25.4618H23.1326C23.6535 25.4618 24.0757 25.0396 24.0757 24.5187C24.0757 23.9979 23.6535 23.5757 23.1326 23.5757H17.4745Z"
                fill="white"
              />
            </svg>
            <p>Message Swapper</p>
          </div>
        </section>
        <section className="ProductPage__side__reviews">
          <p className="ProductPage__side__reviews__heading">Swapper Reviews</p>
          <div className="ProductPage__side__reviews__review">
            <div className="ProductPage__side__reviews__review__rate">
              <svg
                width="108"
                height="18"
                viewBox="0 0 108 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.59035 2.01428C9.46437 1.76231 9.20683 1.60315 8.92512 1.60315C8.64341 1.60315 8.38588 1.76231 8.25989 2.01428L6.20064 6.13278L1.74641 6.81805C1.46242 6.86174 1.22901 7.06509 1.14682 7.34041C1.06464 7.61573 1.14839 7.91377 1.36196 8.10597L4.75502 11.1597L3.73792 15.5672C3.67121 15.8563 3.78269 16.1573 4.02159 16.3333C4.26049 16.5092 4.5811 16.5263 4.83738 16.3768L8.92512 13.9923L13.0129 16.3768C13.2691 16.5263 13.5898 16.5092 13.8287 16.3333C14.0676 16.1573 14.179 15.8563 14.1123 15.5672L13.0952 11.1597L16.4883 8.10597C16.7019 7.91377 16.7856 7.61573 16.7034 7.34041C16.6212 7.06509 16.3878 6.86174 16.1038 6.81805L11.6496 6.13278L9.59035 2.01428Z"
                  fill="#FEE440"
                />
                <path
                  d="M31.9031 2.01428C31.7771 1.76231 31.5196 1.60315 31.2379 1.60315C30.9562 1.60315 30.6986 1.76231 30.5726 2.01428L28.5134 6.13278L24.0592 6.81805C23.7752 6.86174 23.5418 7.06509 23.4596 7.34041C23.3774 7.61573 23.4611 7.91377 23.6747 8.10597L27.0678 11.1597L26.0507 15.5672C25.984 15.8563 26.0954 16.1573 26.3343 16.3333C26.5732 16.5092 26.8938 16.5263 27.1501 16.3768L31.2379 13.9923L35.3256 16.3768C35.5819 16.5263 35.9025 16.5092 36.1414 16.3333C36.3803 16.1573 36.4918 15.8563 36.4251 15.5672L35.408 11.1597L38.801 8.10597C39.0146 7.91377 39.0984 7.61573 39.0162 7.34041C38.934 7.06509 38.7006 6.86174 38.4166 6.81805L33.9623 6.13278L31.9031 2.01428Z"
                  fill="#FEE440"
                />
                <path
                  d="M76.5281 2.01428C76.4021 1.76231 76.1446 1.60315 75.8629 1.60315C75.5812 1.60315 75.3236 1.76231 75.1976 2.01428L73.1384 6.13278L68.6842 6.81805C68.4002 6.86174 68.1668 7.06509 68.0846 7.34041C68.0024 7.61573 68.0861 7.91377 68.2997 8.10597L71.6928 11.1597L70.6757 15.5672C70.609 15.8563 70.7204 16.1573 70.9593 16.3333C71.1982 16.5092 71.5188 16.5263 71.7751 16.3768L75.8629 13.9923L79.9506 16.3768C80.2069 16.5263 80.5275 16.5092 80.7664 16.3333C81.0053 16.1573 81.1168 15.8563 81.0501 15.5672L80.033 11.1597L83.426 8.10597C83.6396 7.91377 83.7234 7.61573 83.6412 7.34041C83.559 7.06509 83.3256 6.86174 83.0416 6.81805L78.5873 6.13278L76.5281 2.01428Z"
                  fill="#FEE440"
                />
                <path
                  d="M54.2156 2.01428C54.0896 1.76231 53.8321 1.60315 53.5504 1.60315C53.2687 1.60315 53.0111 1.76231 52.8851 2.01428L50.8259 6.13278L46.3717 6.81805C46.0877 6.86174 45.8543 7.06509 45.7721 7.34041C45.6899 7.61573 45.7736 7.91377 45.9872 8.10597L49.3803 11.1597L48.3632 15.5672C48.2965 15.8563 48.4079 16.1573 48.6468 16.3333C48.8857 16.5092 49.2063 16.5263 49.4626 16.3768L53.5504 13.9923L57.6381 16.3768C57.8944 16.5263 58.215 16.5092 58.4539 16.3333C58.6928 16.1573 58.8043 15.8563 58.7376 15.5672L57.7205 11.1597L61.1135 8.10597C61.3271 7.91377 61.4109 7.61573 61.3287 7.34041C61.2465 7.06509 61.0131 6.86174 60.7291 6.81805L56.2748 6.13278L54.2156 2.01428Z"
                  fill="#FEE440"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M98.1754 1.60315C98.4571 1.60315 98.7146 1.76231 98.8406 2.01428L100.9 6.13278L105.354 6.81805C105.638 6.86174 105.871 7.06509 105.954 7.34041C106.036 7.61573 105.952 7.91376 105.739 8.10597L102.345 11.1597L103.363 15.5672C103.429 15.8563 103.318 16.1573 103.079 16.3333C102.84 16.5092 102.519 16.5263 102.263 16.3768L98.1754 13.9923L94.0876 16.3768C93.8313 16.5263 93.5107 16.5092 93.2718 16.3333C93.0329 16.1573 92.9215 15.8563 92.9882 15.5672L94.0053 11.1597L90.6122 8.10597C90.3986 7.91376 90.3149 7.61573 90.3971 7.34041C90.4793 7.06509 90.7127 6.86174 90.9967 6.81805L95.4509 6.13278L97.5101 2.01428C97.6361 1.76231 97.8937 1.60315 98.1754 1.60315ZM98.1754 4.00998L96.6093 7.14201C96.5015 7.35778 96.2956 7.50782 96.0572 7.5445L92.7733 8.04972L95.326 10.3472C95.527 10.5281 95.614 10.8038 95.5532 11.0673L94.8245 14.2249L97.8006 12.4888C98.0322 12.3538 98.3185 12.3538 98.5501 12.4888L101.526 14.2249L100.798 11.0673C100.737 10.8038 100.824 10.5281 101.025 10.3472L103.577 8.04972L100.294 7.5445C100.055 7.50782 99.8493 7.35778 99.7414 7.14201L98.1754 4.00998Z"
                  fill="#E0E0E0"
                />
              </svg>
              <p>Today, 12:30 pm</p>
            </div>
            <div className="ProductPage__side__reviews__review__reviewer">
              <img src={phone} alt="gbiaye-barnabas" />
              <p>Erin Mango</p>
            </div>
            <p>Everything is good. Nice quality</p>
          </div>
          <div className="ProductPage__side__reviews__review">
            <div className="ProductPage__side__reviews__review__rate">
              <svg
                width="108"
                height="18"
                viewBox="0 0 108 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.59035 2.01428C9.46437 1.76231 9.20683 1.60315 8.92512 1.60315C8.64341 1.60315 8.38588 1.76231 8.25989 2.01428L6.20064 6.13278L1.74641 6.81805C1.46242 6.86174 1.22901 7.06509 1.14682 7.34041C1.06464 7.61573 1.14839 7.91377 1.36196 8.10597L4.75502 11.1597L3.73792 15.5672C3.67121 15.8563 3.78269 16.1573 4.02159 16.3333C4.26049 16.5092 4.5811 16.5263 4.83738 16.3768L8.92512 13.9923L13.0129 16.3768C13.2691 16.5263 13.5898 16.5092 13.8287 16.3333C14.0676 16.1573 14.179 15.8563 14.1123 15.5672L13.0952 11.1597L16.4883 8.10597C16.7019 7.91377 16.7856 7.61573 16.7034 7.34041C16.6212 7.06509 16.3878 6.86174 16.1038 6.81805L11.6496 6.13278L9.59035 2.01428Z"
                  fill="#FEE440"
                />
                <path
                  d="M31.9031 2.01428C31.7771 1.76231 31.5196 1.60315 31.2379 1.60315C30.9562 1.60315 30.6986 1.76231 30.5726 2.01428L28.5134 6.13278L24.0592 6.81805C23.7752 6.86174 23.5418 7.06509 23.4596 7.34041C23.3774 7.61573 23.4611 7.91377 23.6747 8.10597L27.0678 11.1597L26.0507 15.5672C25.984 15.8563 26.0954 16.1573 26.3343 16.3333C26.5732 16.5092 26.8938 16.5263 27.1501 16.3768L31.2379 13.9923L35.3256 16.3768C35.5819 16.5263 35.9025 16.5092 36.1414 16.3333C36.3803 16.1573 36.4918 15.8563 36.4251 15.5672L35.408 11.1597L38.801 8.10597C39.0146 7.91377 39.0984 7.61573 39.0162 7.34041C38.934 7.06509 38.7006 6.86174 38.4166 6.81805L33.9623 6.13278L31.9031 2.01428Z"
                  fill="#FEE440"
                />
                <path
                  d="M76.5281 2.01428C76.4021 1.76231 76.1446 1.60315 75.8629 1.60315C75.5812 1.60315 75.3236 1.76231 75.1976 2.01428L73.1384 6.13278L68.6842 6.81805C68.4002 6.86174 68.1668 7.06509 68.0846 7.34041C68.0024 7.61573 68.0861 7.91377 68.2997 8.10597L71.6928 11.1597L70.6757 15.5672C70.609 15.8563 70.7204 16.1573 70.9593 16.3333C71.1982 16.5092 71.5188 16.5263 71.7751 16.3768L75.8629 13.9923L79.9506 16.3768C80.2069 16.5263 80.5275 16.5092 80.7664 16.3333C81.0053 16.1573 81.1168 15.8563 81.0501 15.5672L80.033 11.1597L83.426 8.10597C83.6396 7.91377 83.7234 7.61573 83.6412 7.34041C83.559 7.06509 83.3256 6.86174 83.0416 6.81805L78.5873 6.13278L76.5281 2.01428Z"
                  fill="#FEE440"
                />
                <path
                  d="M54.2156 2.01428C54.0896 1.76231 53.8321 1.60315 53.5504 1.60315C53.2687 1.60315 53.0111 1.76231 52.8851 2.01428L50.8259 6.13278L46.3717 6.81805C46.0877 6.86174 45.8543 7.06509 45.7721 7.34041C45.6899 7.61573 45.7736 7.91377 45.9872 8.10597L49.3803 11.1597L48.3632 15.5672C48.2965 15.8563 48.4079 16.1573 48.6468 16.3333C48.8857 16.5092 49.2063 16.5263 49.4626 16.3768L53.5504 13.9923L57.6381 16.3768C57.8944 16.5263 58.215 16.5092 58.4539 16.3333C58.6928 16.1573 58.8043 15.8563 58.7376 15.5672L57.7205 11.1597L61.1135 8.10597C61.3271 7.91377 61.4109 7.61573 61.3287 7.34041C61.2465 7.06509 61.0131 6.86174 60.7291 6.81805L56.2748 6.13278L54.2156 2.01428Z"
                  fill="#FEE440"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M98.1754 1.60315C98.4571 1.60315 98.7146 1.76231 98.8406 2.01428L100.9 6.13278L105.354 6.81805C105.638 6.86174 105.871 7.06509 105.954 7.34041C106.036 7.61573 105.952 7.91376 105.739 8.10597L102.345 11.1597L103.363 15.5672C103.429 15.8563 103.318 16.1573 103.079 16.3333C102.84 16.5092 102.519 16.5263 102.263 16.3768L98.1754 13.9923L94.0876 16.3768C93.8313 16.5263 93.5107 16.5092 93.2718 16.3333C93.0329 16.1573 92.9215 15.8563 92.9882 15.5672L94.0053 11.1597L90.6122 8.10597C90.3986 7.91376 90.3149 7.61573 90.3971 7.34041C90.4793 7.06509 90.7127 6.86174 90.9967 6.81805L95.4509 6.13278L97.5101 2.01428C97.6361 1.76231 97.8937 1.60315 98.1754 1.60315ZM98.1754 4.00998L96.6093 7.14201C96.5015 7.35778 96.2956 7.50782 96.0572 7.5445L92.7733 8.04972L95.326 10.3472C95.527 10.5281 95.614 10.8038 95.5532 11.0673L94.8245 14.2249L97.8006 12.4888C98.0322 12.3538 98.3185 12.3538 98.5501 12.4888L101.526 14.2249L100.798 11.0673C100.737 10.8038 100.824 10.5281 101.025 10.3472L103.577 8.04972L100.294 7.5445C100.055 7.50782 99.8493 7.35778 99.7414 7.14201L98.1754 4.00998Z"
                  fill="#E0E0E0"
                />
              </svg>
              <p>Today, 12:30 pm</p>
            </div>
            <div className="ProductPage__side__reviews__review__reviewer">
              <img src={phone} alt="gbiaye-barnabas" />
              <p>Erin Mango</p>
            </div>
            <p>Everything is good. Nice quality</p>
          </div>
        </section>
      </aside>
      <Footer />
    </main>
  );
}
export default ProductPage;