import React from 'react';
import Header from "../../components/header/Header";
// import Footer from '../../components/footer/Footer';
import './privacy.scss';
import WebFooter from '../../components/webFooter/WebFooter';


function TermsOfUse() {
    return (
        <div className="Privacy">

            <Header Main bs="bs" />
            <div className='Privacy_Policy'>
                {/* <h5 className='Privacy_Header'>Privacy & Security</h5> */}
                <h3 className='Main_Header'>Terms & Conditions</h3>
                <div className='Privacy__container'>
                    <p className='Minor_Header'>Kapitify terms of use</p>
                    <div>
                        <p className='Sub_Header'>Introduction</p>

                        <p className='Content'>
                            Welcome to Kapitify. By accessing <a href="www.kapitify.com">www.kapitify.com</a> and its related websites, services, applications and
                            tools (collectively referred to as "Kapitify") you are agreeing to the following terms, including those available by hyperlink which are designed to make sure that Kapitify works for everyone. Kapitify is provided to you by Kapitify Labs, Ugbowo campus, University of Benin, Benin City. Registered in Nigeria.
                            This terms of use constitute a legally binding agreement between you and Kapitify Labs and are effective as of 25 March 2022 for current users, and upon acceptance for new users. You accept this terms of service by clicking the "Create Account" button when registering your Kapitify account and by otherwise accessing Kapitify, including posting an ad; or as otherwise indicated on Kapitify.  </p>
                    </div>

                    <div>
                        <p className='Sub_Header'>Using Kapitify</p>
                        <p className='Content'>As a condition for your use of Kapitify you agree that you will not:</p>
                        <ul>
                            <li>Violate any of the policies.</li>
                            <li>Post any threatening, abusive, defamatory, obscene or indecent material</li>

                            <li>Post or otherwise communicate any misleading material or message of any kind;</li>

                            <li>Infringe any third-party right;</li>

                            <li>Distribute spam, promote pyramid or Ponzi schemes
                            </li>
                            <li>Impose or contribute to imposing an unreasonable load on our infrastructure or interfere with
                                the proper working of Kapitify.</li>
                            <li>Distribute viruses or any other technologies that can harm Kapitify or the interest and property
                                of Kapitify users</li>

                            <li>Copy, modify or distribute any other person's content without their consent;</li>
                            <li>Use any robot spider, scraper or other automated means to access Kapitify and collect for any
                                purpose without our express written permission;</li>
                            <li>Harvest or otherwise collect information about others, including email addresses without their
                                consent</li>
                            <li>Copy, modify or distribute rights or contents from Kapitify or Kapitify's copyright and trademarks</li>
                            <li>Bypass measures used to prevent or restrict access Kapitify</li>
                            <li>Use any tool that interferes with the normal functioning of Kapitify, including, without limitation,
                                browser plug-ins extensions, or other software which are designed to supplement, remove or
                                change any of Kapitify's functionalities or Sell any counterfeit items or otherwise infringe the
                                Copyright, trademark or other rights to third parties.</li>
                        </ul>
                    </div>

                </div>

            </div>
            <WebFooter />
        </div>
    )
}
export default TermsOfUse