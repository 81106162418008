import React from 'react';
import './blogbanner.scss';
//import blogImg from '../../props/images/blogImage.svg';

function BlogBanner(){
  return(
    <div className="BlogBanner">
        <div className="BlogBanner__image">
        {/* <img src={blogImg} alt=""  /> */}
        </div>
    </div>
  )
}
export default BlogBanner;