import React from 'react';
import msg from '../../props/icons/floating-msgIcon.svg';
import './floatingMsg.scss'
function FloatingMsg() {
    return (
        <>
            <img src={msg} alt="" className='floatingMsg'/>
        </>
    );
}

export default FloatingMsg;